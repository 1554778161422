<template>
  <div class="catalog">
    <div class="catalog-tools">
        <el-input style="width:30%;" v-model="search" placeholder="חיפוש" />
    </div>
    <div class="catalog-content">
        <table>
                <tr>
                    <th>פריט</th>
                    <th>שם פריט</th>
                    <th>שם פריט לועזית</th>
                    <th>פעולות</th>
                </tr>
                <template v-for="item in filter_by_search" :key="item['פריט']">
                    <tr>
                        <td>{{item['פריט']}}</td>
                        <td>{{item['שם פריט']}}</td>
                        <td>{{item['שם פריט לועזית']}}</td>
                        <td>
                            <i @click="handle_delete_item(item)" class="material-icons icon delete-icon">delete</i>
                            <i @click="handle_edit_item(item)" class="material-icons icon edit-icon">edit</i>
                        </td>
                    </tr>
                </template>
        </table>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core';
import {alert_confirm} from '../../../../../Methods/Msgs'
import {delete_item_from_db} from '../scripts/scripts'

export default {
    props:['data'],
    emits:['delete_item','edit_item'],
    setup(props,{emit}){
        
        const search = ref('')

        const filter_by_search = ref(computed(()=>{
            return props.data.filter(item=>{
                if(search.value=='')return props.data
                if(item['פריט'].includes(search.value)) return item
                if(item['שם פריט'].includes(search.value)) return item
                if(item['שם פריט לועזית'].includes(search.value)) return item
            })
        }))
        
        const handle_delete_item = (item) => {
            alert_confirm(`למחוק את ${item['שם פריט']}?`)
            .then(async res=>{
                if(res.isConfirmed){
                    await delete_item_from_db(item)
                    emit('delete_item',item)
                }
            })
        }

        const handle_edit_item = (item) => {
            emit('edit_item',JSON.parse(JSON.stringify(item)))
        }

        return{
            handle_delete_item,
            handle_edit_item,
            search,
            filter_by_search,
        }
    }
}
</script>

<style scoped>
    .catalog{
        width: 100%;
        height: 100%;
    }
    .catalog-tools{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .catalog-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow-y: auto;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    .icon{
        cursor: pointer;
        margin: 0 5px;
        user-select: none;
    }
    .delete-icon{
        color: var(--danger);
    }
    .edit-icon{
        color: var(--warning);
    }
</style>